import { Company } from './company'
import InputService, { Input, InputUseStageSummary } from './input'
import UnitService, { EmptyUnit, Unit, UnitType } from './unit'
import { Tag } from './tag'
import {
    Amount,
    KeyValuePair,
    ListResponse,
    ND,
    QueryOptions,
    UsedIn,
    VariableBaseNode,
    VisualDirection,
} from '../types'
import { DataImportResponse, DataImportType } from './dataImport'
import { Taxonomy } from './taxonomy'
import { SharingToken, Token } from './token'
import { Part } from './part'
import { DataSource } from './dataSource'
import Utils from './utils'
import { FileData } from './file'
import { GHGScope } from './ghg'
import { GeoLocation } from './geoLocation'
import { CategoryModel } from './category-model'
import { ActionMap } from '../context'
import { Inventory, InventoryActionType, InventoryService } from './inventory'
import VariableService from './service'
import {
    PactBiogenicAccountingMethodology,
    PactCharacterizationFactor,
    PactCrossSectoralStandard,
    PactIntensityMetrics,
    ProductOrSectorSpecificRuleOperator,
} from './pact'
import { UseStageType } from './useStage'
import AuthenticationService from './authentication'
import { Location } from './location'
import FlagService, { FlagType } from './flag'
import { ElectricityMethod } from './electricity'

export type ProductType = 'product' | 'part'

export enum ProductFootprintType {
    UNKNOWN = -100,
    LIVE = 0,
    CONNECTED = 50,
    STATIC = 100,
    FACTOR = 200,
    ELECTRICITY = 300,
}

export const productFootprintTypeOptions: KeyValuePair<ProductFootprintType>[] = [
    {
        name: 'Live Footprint',
        value: ProductFootprintType.LIVE,
        description:
            'Add input materials and processing steps and connect directly with suppliers to gather live data.',
    },
    {
        name: 'Imported Data',
        value: ProductFootprintType.STATIC,
        description: 'Add a documented emission factor from an LCA or other source that can verify the data.',
    },
    {
        name: 'Average Emission Factor',
        value: ProductFootprintType.FACTOR,
        description: 'Select from our database of average emission factors to make a rough estimate.',
    },
]

export enum ProductState {
    ARCHIVED = 0,
    CREATED = 100,
    SHARED = 200,
    PASSPORT = 300,
}

export enum ProductVisibility {
    ARCHIVED = -100,
    PRIVATE = 0,
    LINK = 50,
    API = 75,
    AUTHENTICATED_USERS = 100,
    PUBLIC = 500,
    PAID_USERS = 600,
}

export interface PassportDisplayConfig {
    showImage?: boolean
    direction?: VisualDirection
}

export interface Product extends VariableBaseNode, PactIntensityMetrics {
    name: string
    assemblyName?: string
    slug?: string
    aka?: string[]
    version?: number
    weight?: Amount
    derivedWeight?: Amount
    description?: string
    productImageUrl?: string
    externalLink?: string
    visibility: ProductVisibility
    isSolution?: boolean
    isVerified?: boolean
    premium?: boolean
    productOf?: Company | null
    state: ProductState
    type: ProductFootprintType
    footprintProduct?: Product
    factor?: Factor
    categoryModel?: CategoryModel
    quality: DataQuality
    qualityRating?: number
    qualitySummary?: QualitySummary
    sourceForCount?: number
    partOfCount?: number
    activityCount?: number
    liveSourceProducts?: number
    dataSources?: DataSource[]
    unit?: Unit
    unitQuantity?: number
    useStageSummary?: InputUseStageSummary[]
    location?: Location | null
    geoLocation?: GeoLocation | null
    tags?: Tag[]
    usedIn?: UsedIn[]
    documents?: FileData[]
    taxonomy?: Taxonomy | null
    scope?: GHGScope
    hasCo2e?: boolean
    co2e?: string | null
    upstreamCo2e?: string | null
    downstreamCo2e?: string | null
    staticUpstreamCo2e?: string | null
    staticDownstreamCo2e?: string | null
    sku?: string
    cpcCode?: string
    notes?: string
    token?: SharingToken
    exchangeToken?: Token

    dppConfig?: PassportDisplayConfig

    metaData?: {}

    method?: ElectricityMethod
    renewableCo2e?: string
    renewablePercentage?: number
    coveredCo2e?: string
    coveredPercentage?: number

    // dates
    co2eCalculated?: number
    startDate?: number
    endDate?: number

    bucketWeight?: number

    // dromo imports
    dataImportId?: string
    // airtable row id
    syncId?: string

    // calculated
    editable?: boolean
}

export interface ProductWithInputs extends Product {
    inputs?: Input[]
}

export type PathfinderDataQualityRatingValue = 1 | 2 | 3

type PathfinderAttributeType =
    | 'co2e'
    | 'number'
    | 'boolean'
    | 'text'
    | 'selector'
    | 'list'
    | 'secondaryEmissionFactorSources'
    | 'productOrSectorSpecificRules'

export type PathfinderAttribute = {
    name: string
    key: string
    type: PathfinderAttributeType
    required?: boolean
    options?: KeyValuePair<
        | PathfinderAttributeType
        | PactCharacterizationFactor
        | ProductOrSectorSpecificRuleOperator
        | PactCrossSectoralStandard
        | PactBiogenicAccountingMethodology
    >[]
}

export const pathfinderAttributes: PathfinderAttribute[] = [
    { name: 'PCF Excluding Biogenic', key: 'pCfExcludingBiogenic', type: 'co2e', required: true },
    { name: 'PCF Including Biogenic', key: 'pCfIncludingBiogenic', type: 'co2e', required: true },
    { name: 'Fossil Emissions', key: 'fossilGhgEmissions', type: 'co2e', required: true },
    { name: 'Fossil Carbon Content', key: 'fossilCarbonContent', type: 'co2e', required: true },
    { name: 'Biogenic Carbon Content', key: 'biogenicCarbonContent', type: 'co2e', required: true },
    { name: 'Direct Land Use Change Emissions', key: 'dLucGhgEmissions', type: 'co2e', required: true },
    { name: 'Land Management Emissions', key: 'landManagementGhgEmissions', type: 'co2e', required: true },
    { name: 'Other Biogenic Emissions', key: 'otherBiogenicGhgEmissions', type: 'co2e', required: true },
    { name: 'Indirect Land Use Change Emissions', key: 'iLucGhgEmissions', type: 'co2e', required: true },
    { name: 'Biogenic Carbon Withdrawal', key: 'biogenicCarbonWithdrawal', type: 'co2e', required: true },
    { name: 'Aircraft GHG Emissions', key: 'aircraftGhgEmissions', type: 'co2e' },
    {
        name: 'Characterization Factors',
        key: 'characterizationFactors',
        type: 'selector',
        required: true,
        options: [
            { name: 'AR6', value: 'AR6' },
            { name: 'AR5', value: 'AR5' },
        ],
    },
    {
        name: 'Cross Sectoral Standards Used',
        key: 'crossSectoralStandardsUsed',
        type: 'list',
        required: true,
        options: [
            { name: 'GHG Protocol Product standard', value: 'GHG Protocol Product standard' },
            { name: 'ISO Standard 14067', value: 'ISO Standard 14067' },
            { name: 'ISO Standard 14044', value: 'ISO Standard 14044' },
        ],
    },
    {
        name: 'Product or Sector Specific Rules',
        key: 'productOrSectorSpecificRules',
        type: 'productOrSectorSpecificRules',
        options: [
            { name: 'PEF', value: 'PEF' },
            { name: 'EPD International', value: 'EPD International' },
            { name: 'Other', value: 'Other' },
        ],
    },
    {
        name: 'Biogenic Accounting Methodology',
        key: 'biogenicAccountingMethodology',
        type: 'selector',
        required: true,
        options: [
            { name: 'PEF', value: 'PEF' },
            { name: 'ISO', value: 'ISO' },
            { name: 'GHGP', value: 'GHGP' },
            { name: 'Quantis', value: 'Quantis' },
        ],
    },
    { name: 'Boundary Processes Description', key: 'boundaryProcessesDescription', type: 'text', required: true },
    {
        name: 'Secondary Emission Factor Sources',
        key: 'secondaryEmissionFactorSources',
        type: 'secondaryEmissionFactorSources',
    },
    { name: 'Exempted Emissions Percent', key: 'exemptedEmissionsPercent', type: 'number', required: true },
    { name: 'Exempted Emissions Description', key: 'exemptedEmissionsDescription', type: 'text', required: true },
    { name: 'Packaging Emissions Included', key: 'packagingEmissionsIncluded', type: 'boolean', required: true },
    { name: 'Packaging Emissions', key: 'packagingGhgEmissions', type: 'co2e' },
    { name: 'Allocation Rules Description', key: 'allocationRulesDescription', type: 'text' },
    { name: 'Uncertainty Assessment Description', key: 'uncertaintyAssessmentDescription', type: 'text' },
    { name: 'Primary Data Share', key: 'pcfPrimaryDataShare', type: 'number', required: true },
    { name: 'Coverage Percent', key: 'dqiCoveragePercent', type: 'number', required: true },
    { name: 'Comment', key: 'pcfComment', type: 'text' },
]

export interface QualitySummary {
    averagePercent?: number
    specificPercent?: number
    documentationYear?: number
    technologicalRepresentativeness?: PathfinderDataQualityRatingValue
    temporalRepresentativeness?: PathfinderDataQualityRatingValue
    geographicalRepresentativeness?: PathfinderDataQualityRatingValue
    dataCompleteness?: PathfinderDataQualityRatingValue
    dataReliability?: PathfinderDataQualityRatingValue
}

export const technologicalRepresentativenessOptions: KeyValuePair<PathfinderDataQualityRatingValue | undefined>[] = [
    { name: 'No value', description: <span className='d-block border-bottom mb--1 pb-1' />, value: undefined },
    { name: 'Good', description: 'Same technology', value: 1 },
    { name: 'Fair', description: 'Similar technology (based on secondary data sources)', value: 2 },
    { name: 'Poor', description: 'Different or unknown technology', value: 3 },
]

export const temporalRepresentativenessOptions: KeyValuePair<PathfinderDataQualityRatingValue | undefined>[] = [
    { name: 'No value', description: <span className='d-block border-bottom mb--1 pb-1' />, value: undefined },
    { name: 'Good', description: 'Same reporting year', value: 1 },
    { name: 'Fair', description: 'Less than 5 years old', value: 2 },
    { name: 'Poor', description: 'More than 5 years old', value: 3 },
]

export const geographicalRepresentativenessOptions: KeyValuePair<PathfinderDataQualityRatingValue | undefined>[] = [
    { name: 'No value', description: <span className='d-block border-bottom mb--1 pb-1' />, value: undefined },
    { name: 'Good', description: 'Same country or country subdivision', value: 1 },
    { name: 'Fair', description: 'Same region or subregion', value: 2 },
    { name: 'Poor', description: 'Global or unknown', value: 3 },
]

export const dataCompletenessOptions: KeyValuePair<PathfinderDataQualityRatingValue | undefined>[] = [
    { name: 'No value', description: <span className='d-block border-bottom mb--1 pb-1' />, value: undefined },
    { name: 'Good', description: 'Activity data collected for all relevant sites for specified period', value: 1 },
    {
        name: 'Fair',
        description:
            'Activity data collected for <50% of sites for specified period or >50% of sites for shorter period',
        value: 2,
    },
    {
        name: 'Poor',
        description: 'Activity data collected for <50% of sites for shorter time period or unknown',
        value: 3,
    },
]

export const dataReliabilityOptions: KeyValuePair<PathfinderDataQualityRatingValue | undefined>[] = [
    { name: 'No value', description: <span className='d-block border-bottom mb--1 pb-1' />, value: undefined },
    { name: 'Good', description: 'Measured activity data', value: 1 },
    { name: 'Fair', description: 'Activity data partly based on assumptions', value: 2 },
    { name: 'Poor', description: 'Financial data or non-qualified estimate', value: 3 },
]

export interface Factor {
    footprint?: Product
    footprintUnit?: Unit
    quantity?: number
    unit?: Unit
    co2e?: string
    creator?: Company
}

export interface ProductSearchOptions {
    searchTerm?: string
    queryOptions?: QueryOptions
    queryString?: string
    limit?: number
    page?: number
}

export enum DataQuality {
    VERIFIED = 'verified',
    PRIMARY = 'primary',
    ESTIMATE = 'estimate',
    AVERAGE = 'average',
    MODEL = 'model',
    SPEND = 'spend',
    UNKNOWN = 'unknown',
}

export const dataQualityOptions: KeyValuePair<DataQuality>[] = [
    { value: DataQuality.UNKNOWN, name: 'Unknown' },
    { value: DataQuality.ESTIMATE, name: 'Estimate' },
    { value: DataQuality.SPEND, name: 'Spend' },
    { value: DataQuality.AVERAGE, name: 'Average' },
    { value: DataQuality.MODEL, name: 'Model' },
    { value: DataQuality.PRIMARY, name: 'Primary' },
    { value: DataQuality.VERIFIED, name: 'Verified' },
]

export const EmptyProduct: Product = {
    name: '',
    visibility: ProductVisibility.PRIVATE,
    quality: DataQuality.UNKNOWN,
    unit: UnitService.getDefaultUnit(),
    type: ProductFootprintType.UNKNOWN,
    state: ProductState.CREATED,
}

export const EmptyFactor: Factor = {
    footprint: EmptyProduct,
    quantity: 1,
    unit: EmptyUnit,
}

export interface ProductContext {
    productId?: string
    preview?: Product
    previewClosedId?: string
    deletedId?: string
    highlightPactFields?: boolean
}

export enum ProductActionType {
    SelectProductId = 'SelectProductId',
    SetProductPreview = 'SetProductPreview',
    UnsetProductPreview = 'UnsetProductPreview',
    SetDeletedProductId = 'SetDeletedProductId',
    ClearInstanceId = 'ClearInstanceId',
    SetHighlightPactFields = 'SetHighlightPactFields',
}

type ProductActionPayload = {
    [ProductActionType.SelectProductId]: string | undefined
    [ProductActionType.SetProductPreview]: Product | undefined
    [ProductActionType.UnsetProductPreview]: undefined
    [ProductActionType.SetDeletedProductId]: string | undefined
    [ProductActionType.ClearInstanceId]: undefined
    [ProductActionType.SetHighlightPactFields]: boolean
}

export type ProductActions = ActionMap<ProductActionPayload>[keyof ActionMap<ProductActionPayload>]

export const ProductReducer = (state: ProductContext, action: ProductActions): ProductContext => {
    switch (action.type) {
        case ProductActionType.SelectProductId:
            return { ...state, productId: action.payload }
        case ProductActionType.SetProductPreview:
            return { ...state, preview: action.payload, previewClosedId: undefined, deletedId: undefined }
        case ProductActionType.UnsetProductPreview:
            return { ...state, preview: undefined, previewClosedId: state.preview?.uuid, deletedId: undefined }
        case ProductActionType.SetDeletedProductId:
            return { ...state, preview: undefined, previewClosedId: undefined, deletedId: action.payload }
        case ProductActionType.ClearInstanceId:
            return { ...state, preview: undefined, previewClosedId: undefined, deletedId: undefined }
        case ProductActionType.SetHighlightPactFields:
            return { ...state, highlightPactFields: action.payload }
        default:
            return state
    }
}

export default class ProductService extends VariableService {
    private basePath: string = '/product'
    public static webRoot: string = '/element'
    public static webRootList: string = '/products'
    public static webRootPassport: string = '/passport'
    public static webRootDpp: string = '/dpp'
    public static webRootEmbed: string = '/embed'
    public static webRootDatabase: string = '/database'
    public static DppPrefix = 'VAR-DPP-'
    public static webTitle = (plural: boolean = false): string => Utils.pluralize('Product', plural ? 2 : 1)
    public static elementTitle = (plural: boolean = false): string => Utils.pluralize('Element', plural ? 2 : 1)
    public static footprintTitle = (plural: boolean = false): string => Utils.pluralize('Footprint', plural ? 2 : 1)
    public static databaseTitle = 'Database'
    public static newId = 'new'

    public static basicProduct(product?: Product): Product | undefined {
        if (!product) return undefined
        return {
            uuid: product.uuid,
            syncId: product.syncId,
            name: product.name,
            slug: product.slug,
            sku: product.sku,
            productOf: {
                uuid: product.productOf?.uuid,
                name: product.productOf?.name || '',
                type: product.productOf?.type,
            },
            visibility: product.visibility,
            state: product.state,
            type: product.type,
            quality: product.quality,
        }
    }

    public static getEmptyProduct(properties?: Partial<Product>): Product {
        return { ...EmptyProduct, ...properties }
    }

    public static getProductFootprintTypeString(
        product?: Product,
        footprintType?: ProductFootprintType | null,
    ): string {
        if (product?.labels?.includes(ND.EPD)) {
            return 'EPD'
        }
        if (!footprintType && product?.type !== undefined) footprintType = product?.type
        switch (footprintType) {
            case ProductFootprintType.LIVE:
                return 'Model'
            case ProductFootprintType.ELECTRICITY:
                return 'Electricity'
            case ProductFootprintType.CONNECTED:
                return 'Connected Footprint'
            case ProductFootprintType.STATIC:
                return 'Imported'
            case ProductFootprintType.FACTOR:
                return 'Emission Factor'
            case ProductFootprintType.UNKNOWN:
                return 'Unknown'
        }
        return ''
    }

    public isPremium(product?: Product): boolean {
        if (!product) return false
        return product?.hasCo2e === false || product?.co2e === undefined
    }

    public isPrimary(product?: Product | Inventory): boolean {
        return [DataQuality.PRIMARY, DataQuality.VERIFIED].includes(product?.quality || DataQuality.UNKNOWN)
    }

    public static isEmissionFactor(product?: Product, dataSourceNames?: Set<string>): boolean {
        if (!product) {
            return false
        }
        if (product.labels?.includes(ND.EmissionFactor)) {
            return true
        }
        if (dataSourceNames?.size && product.dataSources?.some((ds) => dataSourceNames?.has(ds.uuid || ''))) {
            return true
        }
        return (
            [DataQuality.AVERAGE, DataQuality.SPEND].includes(product?.quality || DataQuality.UNKNOWN) &&
            !product.productOf
        )
    }

    public static getQualityRatingColor(qr?: number): string {
        qr = qr || 3
        let textColor = Utils.secondaryBackgroundColor
        if (qr <= 1.5) {
            textColor = Utils.successColor
        } else if (qr >= 2.5) {
            textColor = Utils.dangerColor
        }
        return textColor
    }

    public getDatabaseSortOptions(): any[] {
        return [
            { name: 'Relevance', value: 'score', default: true, orderDir: 'DESC' },
            { name: 'Name', value: 'name', defaultDir: 'ASC' },
            { name: Utils.co2e, text: 'CO2e', value: 'co2e', defaultDir: 'ASC' },
        ]
    }

    public getSharingOptions(): KeyValuePair<ProductVisibility>[] {
        const pso: KeyValuePair<ProductVisibility>[] = [
            {
                name: 'Private',
                value: ProductVisibility.PRIVATE,
                description: 'Only people in the company have access',
            },
            {
                name: 'Link',
                value: ProductVisibility.LINK,
                description: 'Anyone with the link can view',
            },
        ]
        if (FlagService.enabledFlags.has(FlagType.EnableSubdomains)) {
            pso.push({
                name: 'API',
                value: ProductVisibility.API,
                description: 'Visible in the API and public URL',
            })
        }
        if (FlagService.enabledFlags.has(FlagType.EnableProductSharing)) {
            pso.push({
                name: 'Discoverable',
                value: ProductVisibility.AUTHENTICATED_USERS,
                description: 'Discoverable in the Database',
            })
            pso.push({
                name: 'Public',
                value: ProductVisibility.PUBLIC,
                description: 'Visible in the Variable Database and public URL',
            })
        }
        // pso.push({
        //     name: 'Archived',
        //     value: ProductVisibility.ARCHIVED,
        //     description: `Not visible and cannot be used in new ${ProductService.elementTitle(
        //         true,
        //     )} or ${ActivityService.webTitle(true)}`,
        // })
        return pso
    }

    public getSharingLabel(product?: Product) {
        switch (product?.visibility) {
            case ProductVisibility.LINK:
                return (
                    <>
                        Anyone with the link can <strong>view</strong>
                    </>
                )
            case ProductVisibility.AUTHENTICATED_USERS:
                return (
                    <>
                        <strong>Discoverable</strong>: Variable users can find this in the database and use it in their{' '}
                        {ProductService.elementTitle(true)}
                    </>
                )
            case ProductVisibility.PUBLIC:
                return (
                    <>
                        <strong>Public</strong>: anyone can find this {ProductService.elementTitle()} in the database
                        and use it in their models
                    </>
                )
            case ProductVisibility.API:
                return (
                    <>
                        Visible in the <strong>API and public URL</strong>
                    </>
                )
            case ProductVisibility.ARCHIVED:
                return (
                    <>
                        Archived, but still visible to anyone <strong>in your company</strong>
                    </>
                )
            default:
                return (
                    <>
                        Visible to anyone <strong>in your company</strong>
                    </>
                )
        }
    }

    public static getAkaTags(product: Product): Tag[] {
        const akaTags: Tag[] =
            product.aka?.map((aka) => ({
                name: aka,
                slug: aka.toLowerCase(),
            })) || []
        product.tags?.forEach((tag) => {
            if (!akaTags.find((t) => t.slug === tag.slug) && tag.slug !== 'variable-onboarding') {
                akaTags.push(tag)
            }
        })
        return akaTags
    }

    public static getProductName(product?: Product | Inventory): string {
        if (product?.aka?.length) {
            return `${product?.aka?.[0]} (${product.name})`
        }
        return product?.name || 'Unnamed Product'
    }

    public static getProductWeight(product?: Product): Amount | undefined {
        let weight = product?.weight
        if (product?.unit?.type === UnitType.WEIGHT) {
            weight = { quantity: product.weight?.quantity || 1, unit: product.unit }
        } else if (!weight?.quantity && !weight?.unit) {
            weight = { quantity: undefined, unit: UnitService.baseUnitByType.get(UnitType.WEIGHT) }
        }
        return weight
    }

    public static isLiveProduct(product?: Product): boolean {
        return product?.liveSourceProducts !== undefined && product.liveSourceProducts > 0
    }

    public static getProductUrl(product?: Partial<Product>, fullUrl: boolean = false): string {
        let url = `${fullUrl ? document.location.origin : ''}${this.webRoot}`
        if (!product) return url
        return `${url}/${product.uuid}`
    }

    public static getPassportUrl(product?: Product, token?: string, pathOnly: boolean = false): string {
        let url = this.webRootPassport
        if (!pathOnly) {
            url = `${document.location.origin}${url}`
        }
        if (!product) {
            return url
        }
        url += `/${token || product.uuid}`
        return url
    }

    public static getDataVizUrl(product?: Product, token?: string): string {
        let url = `${document.location.origin}/t`
        if (!product) {
            return url
        }
        url += `/${token || product.uuid}`
        return url
    }

    public static getPassportCode(product?: Product, token?: string): string {
        return `${ProductService.DppPrefix}${token || product?.uuid}`
    }

    public static getFilterByUrl(filterBy?: string, filterId?: string, fullUrl: boolean = false): string {
        let url = `${fullUrl ? document.location.origin : ''}${this.webRootList}`
        if (!filterBy || !filterId) {
            return url
        }
        return `${url}?filterBy=${filterBy}&id=${filterId}`
    }

    public static isVisibleToUsers(product?: Product): boolean {
        return (product?.visibility || 0) >= ProductVisibility.AUTHENTICATED_USERS
    }

    public isSynced(product?: Product): boolean {
        return !!product?.syncId?.startsWith('pact_') || !!product?.syncId?.startsWith('rec')
    }

    public isEditable(product?: Product): boolean {
        if (!product || product.editable === false) {
            return false
        }
        if (this.isSynced(product) && product.productOf?.uuid !== this.context.stores.company?.uuid) {
            return false
        }
        if (!product?.uuid && product.productOf?.uuid === this.context.stores.company?.uuid) {
            return true
        }
        if (!product.productOf?.claimed && product.productOf?.editable) {
            return true
        }
        return product.editable === true
    }

    public canBeDeleted(product: Product): boolean {
        return !product.usedIn?.length && !product.partOfCount && !product.sourceForCount && !product.activityCount
    }

    public openPreview(product?: Product): void {
        if (!product) return
        this.context.dispatch({ type: ProductActionType.SetProductPreview, payload: product })
    }

    private updateProductAndInventoryList(products?: Product[]): void {
        if (!products?.length) return
        this.context.dispatch({
            type: InventoryActionType.Merge,
            payload: products?.map((p) => InventoryService.productToInventory(p)),
        })
    }

    private patchContext(product: Partial<Product>): void {
        const cached = InventoryService.byId.get(product.uuid || '')?.originalProduct
        if (cached) this.updateProductAndInventoryList([{ ...cached, ...product }])
    }

    public async createProduct(product: Partial<Product>, company?: Company): Promise<Product> {
        return this.httpService
            .post<Product>(this.basePath, {
                body: JSON.stringify({ name: product.name, companyId: company?.uuid }),
            })
            .then((p) => {
                this.updateProductAndInventoryList([p])
                return p
            })
    }

    public async getProductToken(product: Product): Promise<Token> {
        return this.httpService
            .put<Token>(`${this.basePath}/${product.uuid}`, { body: JSON.stringify({ productToken: true }) })
            .then((token) => {
                this.patchContext({ uuid: product.uuid, token })
                return token
            })
    }

    public async setConnectedFootprint(product: Product, footprint: Product, token: string): Promise<Product> {
        return this.httpService.put<Product>(`${this.basePath}/${product.uuid}`, {
            body: JSON.stringify({ connectedFootprint: footprint.uuid, token: token }),
        })
    }

    public async duplicateProduct(product: Product): Promise<Product> {
        return this.httpService
            .post<Product>(this.basePath, { body: JSON.stringify({ duplicate: product }) })
            .then((p) => {
                this.updateProductAndInventoryList([p])
                return p
            })
    }

    public async createProductFrom(product: Product, properties: Partial<Product>): Promise<Product> {
        return this.httpService
            .post<Product>(this.basePath, { body: JSON.stringify({ createFrom: product.uuid, properties }) })
            .then((p) => {
                this.updateProductAndInventoryList([p])
                return p
            })
    }

    public async createProductFromFactor(footprint: Product): Promise<Product | undefined> {
        return this.httpService
            .post<Product | undefined>(this.basePath, {
                body: JSON.stringify({ factorId: footprint.uuid }),
            })
            .then((p) => {
                if (p) this.updateProductAndInventoryList([p])
                return p
            })
            .catch(() => undefined)
    }

    public async get(queryString?: string): Promise<ListResponse<Product>> {
        const qs = new URLSearchParams(queryString)
        return this.httpService.get<ListResponse<Product>>(`${this.basePath}?${qs.toString()}`).then((plr) => {
            const pList = plr.data?.filter((p) => p.productOf?.uuid === this.context.stores.company?.uuid)
            this.updateProductAndInventoryList(pList)
            return plr
        })
    }

    private static slimProperties = [
        'uuid',
        'type',
        'name',
        'sku',
        'aka',
        'editable',
        'updated',
        'description',
        'premium',
        'productOf',
        'productImageUrl',
        'createdBy',
        'usedIn',
        'activityCount',
        'sourceForCount',
        'partOfCount',
        'updatedBy',
        'upstreamCo2e',
        'downstreamCo2e',
        'taxonomy',
        'unit',
        'hasCo2e',
        'co2e',
        'bucketWeight',
        'qualityRating',
        'qualitySummary',
        'useStageSummary',
        'dataImportId',
        'visibility',
    ]

    public getSlimProducts() {
        const qs = new URLSearchParams(`return=${ProductService.slimProperties.join(',')}&limit=-1`)
        this.get(qs.toString()).then((plr) => this.updateProductAndInventoryList(plr.data))
    }

    public async getById(productId: string, cacheOk: boolean = false, quiet: boolean = false): Promise<Product> {
        if (cacheOk) {
            const cached = InventoryService.byId.get(productId)?.originalProduct
            if (cached) return Promise.resolve(cached)
        }
        return this.httpService.get<Product>(`/product/${productId}`).then((p) => {
            if (!quiet) {
                this.updateProductAndInventoryList([p])
                this.context.dispatch({ type: ProductActionType.SelectProductId, payload: p.uuid })
            }
            return p
        })
    }

    public async getPassport(productId: string, token?: string, sendToAuth?: boolean): Promise<Product> {
        const _qs = new URLSearchParams()
        if (token) {
            _qs.set('token', token)
        }
        const qs = _qs.size ? `?${_qs.toString()}` : ''
        return this.httpService
            .get<Product>(`/passport/${productId}${qs}`)
            .then((p) => {
                this.context.dispatch({ type: ProductActionType.SelectProductId, payload: p.uuid })
                return p
            })
            .catch((e) => {
                if (e.status === 403 && sendToAuth) {
                    AuthenticationService.sendUserToAuth()
                }
                throw e
            })
    }

    public async getDataViz(productId: string, token?: string): Promise<any> {
        const _qs = new URLSearchParams()
        if (token) {
            _qs.set('token', token)
        }
        const qs = _qs.size ? `?${_qs.toString()}` : ''
        return this.httpService.get<any>(`/passport/${productId}/results${qs}`)
    }

    public async search(opts: ProductSearchOptions): Promise<ListResponse<Product>> {
        let qs: URLSearchParams
        if (opts.queryOptions) {
            qs = Utils.queryOptionsToURLSearchParams(opts.queryOptions)
        } else {
            qs = new URLSearchParams(opts.queryString)
        }
        qs.set('search', 'true')
        if (opts.searchTerm) qs.set('t', opts.searchTerm)
        if (opts.limit) qs.set('limit', opts.limit.toString())
        if (opts.page) qs.set('page', opts.page.toString())
        const url = `${this.basePath}?${qs.toString()}`
        return this.httpService.get<ListResponse<Product>>(url)
    }

    public async update(product: Partial<Product>): Promise<Product> {
        let url = this.basePath
        if (product.uuid) {
            url += `/${product.uuid}`
        }
        this.patchContext(product)
        return this.httpService
            .put<Product>(url, { body: JSON.stringify({ product: Utils.prepareForSave(product) }) })
            .then((p) => {
                this.updateProductAndInventoryList([p])
                if (this.context.stores.products.productId === ProductService.newId) {
                    this.context.dispatch({ type: ProductActionType.SelectProductId, payload: p.uuid })
                }
                if (this.context.stores.products.preview && !this.context.stores.products.preview?.uuid) {
                    this.openPreview(p)
                }
                return p
            })
    }

    public async deleteProduct(productId?: string): Promise<Product> {
        if (!productId) return Promise.reject('No product id')
        return this.httpService.delete<Product>(`${this.basePath}/${productId}`).then((p) => {
            this.context.dispatch({ type: InventoryActionType.RemoveById, payload: productId })
            return p
        })
    }

    public transferOwnership(productId: string, fromCompanyId: string, toCompanyId: string): Promise<boolean> {
        return this.httpService.post(this.basePath, {
            body: JSON.stringify({
                transferProductId: productId,
                fromCompanyId: fromCompanyId,
                toCompanyId: toCompanyId,
            }),
        })
    }

    public async getInputs(productId: string): Promise<Input[]> {
        return this.httpService.get<Input[]>(`${this.basePath}/${productId}/input`).then((inputs) => {
            InputService.updateContext(inputs)
            return inputs
        })
    }

    public getProductUseStage(productId: string): Promise<UseStageType> {
        return this.httpService.get<UseStageType>(`${this.basePath}/${productId}/useStage`)
    }

    public async createInput(productId: string, input?: Input): Promise<Input> {
        return this.httpService
            .post<Input>(`${this.basePath}/${productId}/input`, { body: JSON.stringify({ newInput: input }) })
            .then((i) => {
                InputService.updateContext([i])
                return i
            })
    }

    public importProducts(productData: Product[], importType?: DataImportType): Promise<DataImportResponse> {
        return this.httpService.post<DataImportResponse>(this.basePath, {
            body: JSON.stringify({ import: productData, importType }),
        })
    }

    public getProductExport(product: Product): Promise<any> {
        return this.httpService.get<any>(`${this.basePath}/${product.uuid}?export=csv`, { responseType: 'text' })
    }

    public getProductsThatSourcePart(part: Part): Promise<Product[]> {
        return this.httpService.get<Product[]>(`${this.basePath}?partId=${part.uuid}`)
    }

    public getOnboardingProduct(): Promise<Product> {
        return this.httpService.get<Product>(`${this.basePath}/onboarding`)
    }

    public setScoreAndRating(): Promise<any> {
        return this.httpService.post<any>(this.basePath, {
            body: JSON.stringify({ setScoreAndRating: true }),
        })
    }

    public getEmbedToken(product: Product): Promise<Token> {
        const t: ND = ND.EmbedToken
        return this.httpService.post<Token>(this.basePath, {
            body: JSON.stringify({ sharingToken: product.uuid, tokenType: t }),
        })
    }

    public getPassportToken(product: Product): Promise<Token> {
        const t: ND = ND.PassportToken
        return this.httpService.post<Token>(this.basePath, {
            body: JSON.stringify({ sharingToken: product.uuid, tokenType: t }),
        })
    }

    public getDataVizToken(product: Product): Promise<Token> {
        const t: ND = ND.DataVizToken
        return this.httpService.post<Token>(this.basePath, {
            body: JSON.stringify({ sharingToken: product.uuid, tokenType: t }),
        })
    }
}
