import { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import Button from '../Input/Button'
import { Input } from '../../services/input'
import { DataQuality, Product } from '../../services/product'
import InputField from '../Input/InputField'
import { VariableNode } from '../../types'
import { CategoryModel, CategoryModelConfig, CategoryModelConfigType } from '../../services/category-model'
import Utils from '../../services/utils'
import { VariableServicesContext } from '../../services'
import Delete from '../Delete'
import { DebugData, Footnotes } from '../Footnotes'
import { useDragNDrop } from '../../hooks/useDragNDrop'
import Card from '../Card'
import { useDrag } from '../../hooks/useDrag'
import { CaretDown, CaretLeft, DotsSixVertical, Target } from '@phosphor-icons/react'
import { useSave } from '../../hooks/useSave'

interface ModelContext {
    selecting: boolean
    setSelecting: (selecting: boolean) => void
    hovering?: CategoryModelConfig
    setHovering: (categoryModel: CategoryModelConfig) => void
    onSelect: (input: Input, type: CategoryModelConfigType, _field: string) => void
    setCategoryModelProduct: (product: Product) => void
    categoryModel?: CategoryModel
    categoryModelConfigs?: CategoryModelConfig[]
    modelConfigUi?: ReactNode
    modelSelectArea: (input: Input, type: CategoryModelConfigType, field: string) => ReactElement
}

const initialContext: ModelContext = {
    selecting: false,
    setSelecting: (_selecting: boolean) => {},
    setHovering: (_categoryModel: CategoryModelConfig) => {},
    onSelect: (_input: Input, _type: CategoryModelConfigType, _field: string) => {},
    setCategoryModelProduct: (_product: Product) => {},
    categoryModel: undefined,
    categoryModelConfigs: undefined,
    modelConfigUi: undefined,
    modelSelectArea: (_input: Input, _type: CategoryModelConfigType, _field: string) => <></>,
}

export const ModelConfigContext = createContext(initialContext)

export const ModelConfigContextProvider = (props: { children: ReactNode }) => {
    const { categoryModelService } = useContext(VariableServicesContext)
    const [selecting, setSelecting] = useState<boolean>(false)
    const [categoryModel, setCategoryModel] = useState<CategoryModel>({ config: [] })
    const [minimized, setMinimized] = useState<boolean>(false)
    const [hovering, setHovering] = useState<CategoryModelConfig>()

    const defaultTop = useMemo(() => 10, [])
    const defaultLeft = useMemo(() => 600, [])

    const move = useDrag({ draggable: 'xy', style: { top: `${defaultTop}px`, left: `${defaultLeft}px` } })

    const partialSaveFn = useCallback(
        async (properties: Partial<CategoryModel>) => {
            return categoryModelService
                .createOrUpdateModel({ uuid: categoryModel.uuid, ...properties })
                .then(setCategoryModel)
        },
        [categoryModel],
    )

    const { pSave, saving } = useSave({ node: categoryModel, partialSaveFn })

    const configs = useMemo(() => categoryModel.config.sort(Utils.sortByOrder), [categoryModel.config])

    const onChangeOrder = useCallback(
        (dragTargetIndex: number, dropTargetIndex: number) => {
            const _configs = [...configs]
            const [removed] = _configs.splice(dragTargetIndex, 1)
            _configs.splice(dropTargetIndex, 0, removed)
            const _newOrder = _configs.map((c, idx) => ({ ...c, order: idx }))
            pSave({ config: _newOrder }).then()
        },
        [configs],
    )

    const { dragHandle, onDragStart, onDragOver, onDragEnd, dragging, getDropTargetClassName } = useDragNDrop({
        onChange: onChangeOrder,
    })

    const setCategoryModelProduct = useCallback(
        (product: Product) => {
            if (product.quality === DataQuality.MODEL && product.uuid && !categoryModel.product) {
                categoryModelService.getModelByProductId(product.uuid).then((cm) => {
                    if (cm.uuid) {
                        setCategoryModel(cm)
                    } else {
                        setCategoryModel({ product, config: [] })
                    }
                })
            }
        },
        [categoryModel],
    )

    const onSelect = useCallback(
        (input: VariableNode, type: CategoryModelConfigType, field: string) => {
            const config = [...categoryModel.config]
            config.push({ type, input: input as Input, field, description: '', order: config.length })
            setSelecting(false)
            pSave({ config }).then()
        },
        [categoryModel],
    )

    const modelSelectArea = useCallback(
        (input: Input, type: CategoryModelConfigType, field: string) => {
            const cmc: CategoryModelConfig = { type, input, field, description: '' }
            const configured = configs.some((c) => c.input?.uuid === cmc.input?.uuid)
            return (
                <Button
                    hidden={!selecting && !hovering}
                    onMouseEnter={() => setHovering(cmc)}
                    onMouseLeave={() => setHovering(undefined)}
                    className={[
                        'fill-parent btn btn-sm rounded-0 z-index-popover',
                        !configured && selecting && 'bg-secondary',
                        configured && selecting && 'bg-success',
                        (!selecting || !configured) &&
                        hovering?.input?.uuid === cmc?.input?.uuid &&
                        hovering?.type === type &&
                        hovering?.field === field
                            ? 'bg-secondary opacity-50'
                            : 'opacity-10',
                    ].join(' ')}
                    onClick={() => !configured && onSelect(input, type, field)}
                />
            )
        },
        [configs, selecting, hovering, onSelect],
    )

    const modelConfigUi = useMemo(
        () => (
            <Card
                className='overflow-hidden position-absolute shadow-sm z-index-popover'
                innerClassName='p-0'
                style={{
                    width: '30rem',
                    top: `${move.offsetTop || defaultTop}px`,
                    left: `${move.offsetLeft || defaultLeft}px`,
                }}
            >
                <div className='py-2 px-3 d-flex align-items-center justify-content-between gap-3 bg-light'>
                    <h6
                        onMouseDown={(e) => move.onMouseDown(e)}
                        onDoubleClick={() => setMinimized(!minimized)}
                        className='m-0 not-selectable flex-grow-1'
                        style={{ cursor: dragging ? 'grabbing' : 'grab' }}
                    >
                        <DotsSixVertical className='nt--2 ms--1' /> Model Config
                    </h6>
                    <Button element='span' onClick={() => setMinimized(!minimized)}>
                        {minimized ? <CaretLeft /> : <CaretDown />}
                    </Button>
                </div>
                <div className={[minimized ? 'd-none' : 'd-flex', 'flex-column gap-2 px-3 py-2'].join(' ')}>
                    {configs?.map((cmc, idx) => {
                        return (
                            <div
                                key={`cmc-${cmc.input?.uuid}-${cmc.field}-${cmc.type}`}
                                className={[
                                    'd-flex align-items-center gap-2',
                                    idx % 2 === 0 ? 'bg-light' : '',
                                    'rounded-2 px-2 py-1',
                                    getDropTargetClassName(idx),
                                ].join(' ')}
                                onMouseEnter={() => setHovering(cmc)}
                                onMouseLeave={() => setHovering(undefined)}
                                draggable={dragging}
                                onDragStart={(e) => onDragStart(e, idx)}
                                onDragOver={(e) => onDragOver(e, idx)}
                                onDragEnd={() => onDragEnd()}
                            >
                                <div className='flex-shrink-0'>{dragHandle}</div>
                                <div className='flex-grow-1'>
                                    <div className='small fw-bold'>
                                        {cmc.input?.name}
                                        <span className='small text-muted ms-2 fw-normal'>
                                            {cmc.type}: {cmc.field}
                                        </span>
                                    </div>
                                    <InputField
                                        placeholder='Description'
                                        defaultValue={cmc.description}
                                        className='variable-form-control bg-white border w-100'
                                        onChanged={(newValue) => {
                                            const config = [...categoryModel.config]
                                            config[idx].description = newValue
                                            pSave({ config }).then()
                                        }}
                                    />
                                </div>
                                <Delete
                                    disabled={cmc.inUse && !Utils.inDebugMode()}
                                    disabledTooltip={
                                        <span className='small'>This config is in use and cannot be deleted</span>
                                    }
                                    iconOnly={true}
                                    deleteFn={async () => {
                                        const config = [...categoryModel.config]
                                        config.splice(idx, 1)
                                        pSave({ config }).then()
                                    }}
                                />
                                <DebugData node={cmc} />
                            </div>
                        )
                    })}
                    <div>
                        <Button
                            className={[
                                'btn btn-sm shadow-none text-primary',
                                selecting ? 'btn-secondary' : 'btn-outline-secondary',
                            ].join(' ')}
                            onClick={() => setSelecting(!selecting)}
                        >
                            <Target size={Utils.verySmallIconSize} /> Add new config
                        </Button>
                    </div>
                    <Footnotes node={categoryModel} extraClassName='smaller' saving={saving} />
                </div>
            </Card>
        ),
        [
            configs,
            categoryModel,
            selecting,
            move,
            hovering,
            dragHandle,
            onDragStart,
            onDragOver,
            onDragEnd,
            dragging,
            getDropTargetClassName,
        ],
    )

    return (
        <ModelConfigContext.Provider
            value={{
                selecting,
                setSelecting,
                onSelect,
                modelConfigUi,
                modelSelectArea,
                categoryModel,
                setCategoryModelProduct,
                hovering,
                setHovering,
            }}
        >
            {props.children}
        </ModelConfigContext.Provider>
    )
}
