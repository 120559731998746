import { DragEvent, useCallback, useMemo, useState } from 'react'

export const useDragNDrop = (props: { onChange?: (dragTargetIndex: number, dropTargetIndex: number) => void }) => {
    const [dragging, setDragging] = useState<boolean>(false)
    const [dragTargetIndex, setDragTargetIndex] = useState<number>()
    const [dropTargetIndex, setDropTargetIndex] = useState<number>()

    const onDragStart = useCallback((e: DragEvent<HTMLDivElement>, idx: number) => {
        e.dataTransfer.effectAllowed = 'move'
        setDragTargetIndex(idx)
    }, [])

    const onDragOver = useCallback((e: DragEvent<HTMLDivElement>, idx: number) => {
        e.preventDefault()
        setDropTargetIndex(idx)
    }, [])

    const dragHandle = useMemo(
        () => (
            <span
                role='button'
                tabIndex={0}
                onMouseDown={() => setDragging(true)}
                onMouseUp={() => setDragging(false)}
                className='btn btn-sm btn-plain text-alt-font text-nowrap border-0 nt--2'
                style={{ cursor: dragging ? 'grabbing' : 'grab' }}
            >
                ::
            </span>
        ),
        [dragging],
    )

    const onDragEnd = useCallback(() => {
        setDragging(false)
        setDragTargetIndex(undefined)
        setDropTargetIndex(undefined)
        if (dragTargetIndex !== undefined && dropTargetIndex !== undefined) {
            props.onChange?.(dragTargetIndex, dropTargetIndex)
        }
    }, [dragTargetIndex, dropTargetIndex])

    const getDropTargetClassName = useCallback(
        (idx: number): string => {
            if (!dragging) return ''
            if (dragTargetIndex === undefined || dropTargetIndex === undefined || dropTargetIndex !== idx) return ''
            if (dropTargetIndex < dragTargetIndex) return 'outline-top'
            if (dropTargetIndex > dragTargetIndex) return 'outline-bottom'
            return ''
        },
        [dragging, dragTargetIndex, dropTargetIndex],
    )

    return {
        dragging,
        dragTargetIndex,
        dropTargetIndex,
        setDragging,
        setDragTargetIndex,
        setDropTargetIndex,
        getDropTargetClassName,
        dragHandle,
        onDragStart,
        onDragOver,
        onDragEnd,
    }
}
