import { ActionMap } from '../context'
import { Product } from './product'
import { Part } from './part'
import { Input } from './input'
import { Amount, VariableBaseNode, VariableNode } from '../types'
import VariableService from './service'
import { ProcessingType } from './processing'
import { TransportInstance } from './transport'
import { UseStageType } from './useStage'
import { Taxonomy } from './taxonomy'
import { GeoLocation } from './geoLocation'
import { Location } from './location'

export type CategoryModelConfigType = 'text' | 'unit' | 'element' | 'amount' | 'use-stage' | 'transport' | 'processing'

export interface CategoryModelConfig extends VariableBaseNode {
    type: CategoryModelConfigType
    order?: number
    input?: Input
    field: string
    description: string
    taxonomy?: Taxonomy[]
    inUse?: boolean
}

export interface CategoryModel extends VariableBaseNode {
    product?: Product
    config: CategoryModelConfig[]
}

export interface CategoryModelConfigValue extends VariableBaseNode {
    configId?: string
    amount?: Amount
    part?: Part
    sourceProduct?: Product
    co2e?: string
    countryOfOrigin?: Location | GeoLocation
    countryOfProduction?: Location | GeoLocation
    transportInstance?: TransportInstance
    useStageType?: UseStageType
    processingType?: ProcessingType
}

export interface CategoryModelInstance extends VariableBaseNode {
    co2e?: string
    upstreamCo2e?: string
    downstreamCo2e?: string
    model: CategoryModel
    node?: VariableNode
    values: CategoryModelConfigValue[]
}

export interface ICategoryModel {
    model?: CategoryModel
    node?: VariableNode
    instance?: CategoryModelInstance
    canDeleteInstance?: boolean
    deletedInstance?: CategoryModelInstance
    updates?: number
}

export enum CategoryModelActionType {
    SetCategoryModelConfig = 'SetCategoryModelConfig',
    SetInstance = 'SetCategoryModelInstance',
    DeleteInstance = 'DeleteCategoryModelInstance',
    UpdateInstance = 'UpdateCategoryModelInstance',
    DeselectCategoryModel = 'DeselectCategoryModel',
}

type CategoryModelActionPayload = {
    [CategoryModelActionType.SetCategoryModelConfig]: ICategoryModel
    [CategoryModelActionType.SetInstance]: CategoryModelInstance
    [CategoryModelActionType.UpdateInstance]: CategoryModelInstance
    [CategoryModelActionType.DeleteInstance]: CategoryModelInstance
    [CategoryModelActionType.DeselectCategoryModel]: undefined
}

export type CategoryModelActions = ActionMap<CategoryModelActionPayload>[keyof ActionMap<CategoryModelActionPayload>]

export const CategoryModelReducer = (state: ICategoryModel, action: CategoryModelActions): ICategoryModel => {
    switch (action.type) {
        case CategoryModelActionType.SetCategoryModelConfig:
            return { updates: 0, ...action.payload }
        case CategoryModelActionType.SetInstance:
            return { ...state, updates: 0, instance: action.payload }
        case CategoryModelActionType.UpdateInstance:
            return {
                ...state,
                instance:
                    state.instance?.uuid === action.payload.uuid
                        ? { ...state.instance, ...action.payload }
                        : state.instance,
                updates: state.instance?.uuid === action.payload.uuid ? (state.updates || 0) + 1 : state.updates,
            }
        case CategoryModelActionType.DeleteInstance:
            return { deletedInstance: action.payload }
        case CategoryModelActionType.DeselectCategoryModel:
            return {}
        default:
            return state
    }
}

export default class CategoryModelService extends VariableService {
    private basePath: string = '/model'

    public setCategoryModelConfig(model: CategoryModel, node: VariableNode): void {
        this.context.dispatch({ type: CategoryModelActionType.SetCategoryModelConfig, payload: { model, node } })
    }

    public setCategoryModelInstance(instance: CategoryModelInstance): void {
        this.context.dispatch({ type: CategoryModelActionType.SetInstance, payload: instance })
    }

    public updateCategoryModelInstance(instance: CategoryModelInstance): void {
        this.context.dispatch({ type: CategoryModelActionType.UpdateInstance, payload: instance })
    }

    public deleteCategoryModelInstance(instance: CategoryModelInstance): void {
        this.context.dispatch({ type: CategoryModelActionType.DeleteInstance, payload: instance })
    }

    public deselectCategoryModel(): void {
        this.context.dispatch({ type: CategoryModelActionType.DeselectCategoryModel })
    }

    public getModelById(categoryModelId: string): Promise<CategoryModel> {
        return this.httpService.get<CategoryModel>(`${this.basePath}/${categoryModelId}`)
    }

    public getModelByProductId(productId: string): Promise<CategoryModel> {
        const qs = new URLSearchParams()
        qs.set('productId', productId)
        return this.httpService.get<CategoryModel>(`${this.basePath}?${qs.toString()}`)
    }

    public createOrUpdateModel(categoryModel: Partial<CategoryModel>): Promise<CategoryModel> {
        return this.httpService.put<CategoryModel>(this.basePath, { body: JSON.stringify({ categoryModel }) })
    }

    public async getInstanceByNodeId(nodeId: string): Promise<CategoryModelInstance> {
        const qs = new URLSearchParams()
        qs.set('nodeId', nodeId)
        return this.httpService
            .get<CategoryModelInstance>(`${this.basePath}/instance?${qs.toString()}`)
            .then((instance) => {
                this.updateCategoryModelInstance(instance)
                return instance
            })
    }

    public async saveInstance(categoryModelInstance: CategoryModelInstance): Promise<CategoryModelInstance> {
        return this.httpService
            .post<CategoryModelInstance>(`${this.basePath}/instance`, {
                body: JSON.stringify({ categoryModelInstance }),
            })
            .then((instance) => {
                this.updateCategoryModelInstance(instance)
                return instance
            })
    }

    public saveConfigValue(
        categoryModelInstance: CategoryModelInstance,
        categoryModelConfigValue: CategoryModelConfigValue,
    ): Promise<CategoryModelInstance> {
        return this.httpService.post<CategoryModelInstance>(`${this.basePath}/instance/${categoryModelInstance.uuid}`, {
            body: JSON.stringify({ categoryModelConfigValue }),
        })
    }
}
