import { DataSource } from './dataSource'
import DataImportService, { DataImport } from './dataImport'
import Utils from './utils'
import VariableService from './service'
import { Product } from './product'
import { CategoryModel, CategoryModelConfigValue, CategoryModelInstance } from './category-model'
import { Part } from './part'

export type SyncType = 'suppliers' | 'supplier' | 'parts' | 'part' | 'supplierProducts' | 'bom' | 'product' | 'products'

export interface MonitorPart {
    Id: string
    PartNumber: string
    Description: string
    CompanyId: string
    ExtraDescription: string
    PartCodeId: string
    Type: number
    SupplierPartLinks?: any[]
}

export interface StartSyncResponse {
    dataImport: DataImport
}

export interface PactSyncResult {
    count: number
    newSyncIds: string[]
}

export interface SyncSourceType {
    dataSource: DataSource
    dataImportService: DataImportService
    syncType?: SyncType
    syncTypeId?: string
    onStart?: (syncing: boolean) => void
    onError?: (err: Error) => void
    onUpdate?: (syncing: boolean) => void
    onFinish?: (syncing: boolean) => void
}

export interface SfObject {
    Id: string
    Name: string
}

export enum SfVariableStatus {
    IN_PROGRESS = 'In progress',
    DONE = 'Done',
}

export interface VariableSubmissionProps {
    FH_Brand__FH_Category__c?: string
    Variable_Link__c: string
    Variable_Emissions__c: number
    Variable_Status__c: SfVariableStatus
    agreement?: RbAgreement
    licensee?: SfObject
    category?: SfObject
    part?: Part
    product?: Product
    model?: CategoryModel
    modelInstance?: CategoryModelInstance
}

export interface RbSubmission extends SfObject, VariableSubmissionProps {
    FH_Brand__FH_Status__c: string
    skus?: RbSku[]
    name?: string
    logo?: string
}

export interface RbSku extends SfObject {
    Variable_Emissions__c: number
}

export interface RbAgreement extends SfObject {
    FH_Brand__FH_Licensee__c?: string
}

interface NobbImportResponse {
    status: string
    message: string
    count: number
    total: number
    lastModifiedDate?: string
}

export enum NobbSortField {
    NobbNo = 'NobbNo',
    OwnerParticipantNo = 'OwnerParticipantNo',
    Created = 'Created',
}

export enum NobbSortOrder {
    Asc = 'asc',
    Desc = 'desc',
}

interface INobbGetItems {
    from?: string
    to?: string
    epdOnly?: boolean
    nobbNo?: number
    pageSize?: number
    maxPages?: number
    sortField?: NobbSortField
    sortOrder?: NobbSortOrder
}

export default class SyncService extends VariableService {
    private basePath: string = '/data-sync'
    private rightsAndBrandsPath: string = `${this.basePath}/rb`
    public static rightsAndBrandsWebRoot = '/rb'

    public async getRbSubmission(submissionId: string) {
        return this.httpService.get<RbSubmission>(`${this.rightsAndBrandsPath}/${submissionId}`)
    }

    public async setRbSubmissionStatus(submissionId: string, status: SfVariableStatus) {
        return this.httpService.patch<RbSubmission>(`${this.rightsAndBrandsPath}/${submissionId}`, {
            body: JSON.stringify({ status }),
        })
    }

    public async setCategoryModel(submissionId: string, modelId: string) {
        return this.httpService.patch<RbSubmission>(`${this.rightsAndBrandsPath}/${submissionId}`, {
            body: JSON.stringify({ modelId }),
        })
    }

    public async updateRbProduct(submissionId: string, product: Partial<Product>) {
        return this.httpService.patch<RbSubmission>(`${this.rightsAndBrandsPath}/${submissionId}`, {
            body: JSON.stringify({ product }),
        })
    }

    public async updateConfigValue(submissionId: string, instanceId: string, configValue: CategoryModelConfigValue) {
        return this.httpService.patch<RbSubmission>(`${this.rightsAndBrandsPath}/${submissionId}`, {
            body: JSON.stringify({ instanceId, configValue }),
        })
    }

    public syncNobb(opts?: INobbGetItems): Promise<NobbImportResponse> {
        const qs = new URLSearchParams()
        if (opts?.from) qs.append('from', opts.from)
        if (opts?.to) qs.append('to', opts.to)
        if (opts?.nobbNo) qs.append('nobbNo', opts.nobbNo.toString())
        if (opts?.epdOnly) qs.append('epdOnly', opts.epdOnly.toString())
        if (opts?.pageSize) qs.append('pageSize', opts.pageSize.toString())
        if (opts?.maxPages) qs.append('maxPages', opts.maxPages.toString())
        if (opts?.sortField) qs.append('sortField', opts.sortField)
        if (opts?.sortOrder) qs.append('sortOrder', opts.sortOrder)
        // console.log(opts, qs.toString())
        return this.httpService.get<NobbImportResponse>(`${this.basePath}/nobb?${qs.toString()}`)
    }

    public searchParts(dataSource: DataSource, searchTerm: string): Promise<any> {
        return this.httpService.get<void>(`${this.basePath}/${dataSource.uuid}/part?st=${searchTerm}`)
    }

    public syncSource<T>(dataSource: DataSource, syncType?: SyncType, syncTypeId?: string): Promise<T> {
        let url = `${this.basePath}/${dataSource.uuid}`
        if (syncType) {
            url += `?type=${syncType}`
        }
        if (syncTypeId) {
            url += `&id=${syncTypeId}`
        }
        return this.httpService.post<T>(url)
    }

    public async syncSourceType(opts: SyncSourceType) {
        const response = await this.syncSource<StartSyncResponse>(
            opts.dataSource,
            opts.syncType,
            opts.syncTypeId,
        ).catch((e) => {
            Utils.errorToast(e)
            opts.onError?.(e)
        })

        if (!response?.dataImport?.uuid) {
            opts.onError?.(new Error('No data import uuid returned'))
            return
        }

        opts.onStart?.(true)
        opts.dataImportService.onStartImport(0)
        opts.dataImportService.checkDataImportStatus(
            response.dataImport.uuid,
            0,
            250,
            undefined,
            () => {
                Utils.successToast('Sync complete')
                opts.onFinish?.(false)
            },
            (e) => {
                Utils.errorToast(e, 'Sync failed. See console for more detail.')
                opts.onError?.(e)
            },
        )
    }
}
